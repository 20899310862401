import React, { useState, useEffect } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "../../App.css";
import { useTranslation } from "react-i18next";

import { SwiperSlide, Swiper } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css/bundle";
import image1 from "../../assets/img/logo/becomeOrg.jpg";
import image2 from "../../assets/img/logo/becomeOrg.jpg";
import image3 from "../../assets/img/logo/becomeOrg.jpg";
import image4 from "../../assets/img/logo/becomeOrg.jpg";

const popular_categories = [
  //tab-1 data
  {
    id: 1,
    image: image1,
    title: "Singers",
  },
  {
    id: 2,
    image: image2,
    title: "Bands & Group",
  },
  {
    id: 3,
    image: image3,
    title: "Tributes",
  },
  {
    id: 4,
    image: image4,
    title: "Solo Musicians",
  },
  {
    id: 5,
    image: image1,
    title: "Singers",
  },
  {
    id: 6,
    image: image2,
    title: "Bands & Group",
  },
  {
    id: 7,
    image: image3,
    title: "Tributes",
  },
  {
    id: 8,
    image: image4,
    title: "Solo Musicians",
  },
  //tab-2 data
  {
    id: 9,
    image: image3,
    title: "Tributes",
  },
  {
    id: 10,
    image: image4,
    title: "Solo Musicians",
  },
];

const MostTrendingEvent2 = () => {
  const [data, setData] = useState({});
  const [categoryData, setCategoryData] = useState();
  const [categoryByEvent, setCategoryByEvent] = useState({});
  const [isClicked, setIsClicked] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/public/listTrendingEvents`
        );
        const categoryResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/public/listAllCategory`
        );
        setData(response?.data);
        setCategoryData(categoryResponse?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const navigate = useNavigate();
  const handleClick = (item) => {
    const id = item?._id;
    navigate("/event-details", { state: { id } });
  };
  // ?category=${item._id}
  //  , {
  //     params: {
  //         category: item._id
  //     }
  // }
  const handleEvent = async (item) => {
    try {
      setIsClicked(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/public/getTrendingEventsWithCategory?category=${item?._id}`
      );
      setCategoryByEvent(response?.data);
      setData(response?.data);
    } catch (error) {
      console.log("error in getting trending events", error);
    }
  };

  function textShortner(text) {
    let result = "";

    if (text?.length > 18) {
      result = `${text.slice(0, 18)}...`;
    } else {
      result = text;
    }

    return result;
  }

  const handleEvents = (item) => {
    const id = item._id;
    navigate("event-details", { state: { id } });
    console.log("clicked", id);
  };

  // translation handling
  const { t } = useTranslation();
  const { home } = t("screen");

  return (
    <div className="row justify-center overflow-x-hidden">
      <div className="col-lg-11">
        <section className="ms-popular__area pt-100  fix">
          <div className="container">
            <div className="row align-items-end mb-10 bdFadeUp">
              <div className="col-xl-6 col-lg-6">
                <div className="section__title-wrapper mb-14 bd-title-anim">
                  <span
                    className="section__subtitle"
                    style={{
                      fontFamily: "Oregano-Italic, sans-serif",
                      textTransform: "none",
                    }}
                  >
                    {home?.trendingEventsNearby}
                  </span>
                  <h2
                    className="section__title"
                    style={{
                      fontFamily: "Prompt-Regular, sans-serif",
                      textTransform: "none",
                    }}
                  >
                    {home?.mostTrendingEvents}
                  </h2>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="ms-popular__tab ms-popular-flex mb-14">
                  <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      {categoryData?.category?.map((item, index) => (
                        <button
                          className={`nav-link ${index === 0 ? "active" : ""}`}
                          key={item._id}
                          id={`nav-popular-${index + 1}-tab`}
                          data-bs-toggle="tab"
                          data-bs-target={`#nav-popular-${index + 1}`}
                          type="button"
                          role="tab"
                          aria-controls={`nav-popular-${index + 1}`}
                          aria-selected={index === 0}
                          onClick={() => handleEvent(item)}
                        >
                          {item?.category}
                        </button>
                      ))}
                    </div>
                  </nav>
                </div>
              </div>
            </div>
            <div className="row bdFadeUp">
              <div className="col-xxl-12">
                <div className="tab-content">
                  <div className="tab-pane fade show active">
                    <div className="ms-popular-active fix">
                      <div>
                        <Swiper
                          modules={[Autoplay]}
                          loop={false}
                          spaceBetween={25}
                          autoplay={{
                            delay: 0,
                          }}
                          speed={6000}
                          observeParents={true}
                          observer={true}
                          breakpoints={{
                            1200: {
                              slidesPerView: 4,
                            },
                            992: {
                              slidesPerView: 3,
                            },
                            768: {
                              slidesPerView: 2,
                            },
                            576: {
                              slidesPerView: 2,
                            },
                            0: {
                              slidesPerView: 1,
                            },
                          }}
                        >
                          {data &&
                            data?.events?.map((item, index) => {
                              const imgArray = JSON.parse(item?.eventImage);
                              const firstImgCheck = imgArray?.[0];
                              let firstImg = firstImgCheck
                                ? firstImgCheck
                                : "assets/img/function-brand/function-brand-01.png";

                              return (
                                <SwiperSlide key={index}>
                                  <div
                                    className="ms-popular__item p-relative mb-30"
                                    onClick={() => handleEvents(item)}
                                  >
                                    <div className="ms-popular__thumb responsiveWAndH">
                                      <div className="ms-popular-overlay"></div>
                                      <Link href={`/genres-details/${item.id}`}>
                                        <img
                                          placeholder="blur"
                                          loading="lazy"
                                          src={firstImg}
                                          alt="popular band"
                                        />
                                      </Link>
                                      <Link to="" className="ms-popular__link">
                                        <span className="ms-popular-icon">
                                          <i className="fa-regular fa-arrow-right-long"></i>
                                        </span>
                                      </Link>
                                    </div>
                                    <h4
                                      style={{
                                        fontFamily:
                                          "Prompt-Regular, sans-serif",
                                      }}
                                      className="ms-popular__title"
                                    >
                                      <Link to="">
                                        {textShortner(item.eventName)}
                                      </Link>
                                    </h4>
                                  </div>
                                </SwiperSlide>
                              );
                            })}
                        </Swiper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default MostTrendingEvent2;
