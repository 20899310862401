
import React, { useEffect, useState } from 'react'

import { SwiperSlide, Swiper } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css/bundle';
import { Link, useNavigate } from 'react-router-dom';
import image1 from "../../assets/img/logo/becomeOrg.jpg"
import axios from 'axios';
import { useTranslation } from 'react-i18next';


const brand_data = [
    {
        id: 1,
        songNum: "01",
        image: image1,
        songTitle: "Arcade Fire",
        songText: "Canadian rock group"
    },
    {
        id: 2,
        songNum: "02",
        image: image1,
        songTitle: "Beastie Boys",
        songText: "American hip-hop"
    },
    {
        id: 3,
        songNum: "03",
        image: image1,
        songTitle: "Blondie",
        songText: "American rock group"
    },
    {
        id: 4,
        songNum: "04",
        image: image1,
        songTitle: "Black Sabbath",
        songText: "British rock group"
    },
    {
        id: 5,
        songNum: "05",
        image: image1,
        songTitle: "Boy II Men",
        songText: "Hong Kong Folk"
    },
    {
        id: 6,
        songNum: "06",
        image: image1,
        songTitle: "The Coasters",
        songText: "Canada band group"
    },
    {
        id: 7,
        songNum: "06",
        image: image1,
        songTitle: "The Flamingos",
        songText: "Chicago rock group"
    },
]

const AllEventSwiper = () => {
    const { t } = useTranslation();
    const { home } = t("screen");

    const navigate = useNavigate();
    const [image, setImage] = useState([]);
    const trendingImages = async () => {
        console.log("hitting");
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/public/listTrendingEvents`);
            console.log("response ee", response);
            setImage(response?.data?.events
            )
        } catch (error) {
            console.log("error in getting the events", error);   
        }
    }

    useEffect(() => {
        trendingImages()
    }, [])

    const handleEvents = (item) => {
        const id = item._id;
        navigate("event-details", { state: { id } });
    };


    return (
        <>
            <section className="ms-song-area pt-3 md:mt-20 pb-3 ">
                <div className="container-fluid ms-maw-1710">
                    <div className="ms-song-active fix">
                        <div className="pl-[3rem]">
                            <Swiper
                                modules={[Autoplay]}
                                spaceBetween={25}
                                loop={false}
                                autoplay={{
                                    delay: 3000
                                }}
                                breakpoints={
                                    {
                                        1800: {
                                            slidesPerView: 7
                                        },
                                        1600: {
                                            slidesPerView: 6
                                        },
                                        1400: {
                                            slidesPerView: 5
                                        },
                                        1200: {
                                            slidesPerView: 5
                                        },
                                        992: {
                                            slidesPerView: 4
                                        },
                                        768: {
                                            slidesPerView: 3
                                        },
                                        576: {
                                            slidesPerView: 2
                                        },
                                        450: {
                                            slidesPerView: 2
                                        },
                                        0: {
                                            slidesPerView: 1
                                        },
                                    }
                                }
                            >
                                {
                                    image && image?.length > 0 && image?.map((item, index) => {

                                        const imgArray = JSON.parse(item?.eventImage);
                                        const firstImgCheck = imgArray?.[0];
                                        let firstImg = firstImgCheck ? firstImgCheck : "assets/img/function-brand/function-brand-01.png";

                                        return (
                                            <SwiperSlide key={index}>
                                                <div className="ms-song-item">
                                                    <div className="ms-song-img p-relative">
                                                        <Link to="">
                                                            <img placeholder="blur" loading='lazy'  src={firstImg} alt="brand-song" />
                                                        </Link>
                                                        <span className="ms-song-num">{index+1}</span>
                                                    </div>
                                                    <div className="ms-song-content">
                                                        <h3  onClick={() => handleEvents(item)} className="ms-song-title"><Link to="">{item.eventName}</Link>
                                                        </h3>
                                                        <span className="ms-song-text">{home.ridesAvailable}: {item.ridesAvailable?.length}</span>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    }
                                    )
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AllEventSwiper